<template>
  <SConfirmDialog
    v-model="showDialog"
    :message="`${$t('docs.bulk_actions.delete_question')}`"
    @confirmation-callback="onDeleteAction"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService.js';
import SConfirmDialog from '@/common/components/SConfirmDialog';

export default {
  name: 'BulkDocumentsDeleteDialog',
  components: {
    SConfirmDialog,
  },
  props: {
    value: Boolean,
  },
  computed: {
    ...mapGetters({
      dashboardCheckedDocuments: 'dashboardCheckedDocuments',
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onDeleteAction() {
      const deleteRequests = [];

      for (let document of this.dashboardCheckedDocuments) {
        deleteRequests.push(ContractService.deleteDocs(document.docs_id));
      }

      Promise.allSettled(deleteRequests)
        .then((values) => {
          const undeletedDocuments = values?.filter((result) => 'rejected' === result.status);

          if (undeletedDocuments?.length) {
            return (() => {
              this.$notification.error(this.$t('docs.bulk_action.delete.undone_documents'));
            })();
          }

          this.$notification.success(this.$t('docs.bulk_action.delete.successfully_deleted'));
        })
        .finally(() => {
          this.$emit('refresh-table');
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
