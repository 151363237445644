<template>
  <div
    class="upload__box-wrapper"
    :class="[dragging ? 'upload__box-wrapper--dragging' : '']"
    :style="imageUpload ? 'min-height: 350px !important' : ''"
    @dragenter="dragging = true"
    @dragleave="dragging = false"
  >
    <div class="upload-box">
      <h2 v-if="!files.length">
        <s-help>
          <template v-slot:activator>{{
            imageUpload ? $t('upload.drop_zone.image') : $t('upload.drop_zone')
          }}</template>

          <template
            v-slot:content
            v-if="!imageUpload"
          >
            <p>{{ $t('info.document.upload') }}</p>
          </template>
        </s-help>
      </h2>

      <p v-if="!files.length">{{ $t('general.or') }}</p>

      <div class="upload-box__items">
        <div class="upload-box__item upload-box__item--top-layout upload-box__item--full upload-box__item--auto">
          <label
            v-if="!files.length"
            style="cursor: pointer"
            :for="fileName"
            class="upload-box__item__container"
          >
            <div class="upload-box__ico">
              <IcoUpload />
            </div>

            <div class="upload-box__name">
              {{ $t('contract.choose_file') }}
            </div>
          </label>

          <drop-list
            v-if="files.length > 0"
            :items="files"
            @reorder="$event.apply(files)"
          >
            <template v-slot:item="{ item }">
              <drag
                :key="item.id"
                :data="item"
              >
                <div class="file-upload-box">
                  <span v-if="files.length > 1">
                    <v-icon>mdi-drag </v-icon>
                  </span>

                  <div class="file-upload-box__content">
                    <div class="file-upload-box__name">
                      {{ item.name }}

                      <v-tooltip
                        v-if="item.id === files[0].id && files.length > 1"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            color="primary"
                            outlined
                            x-small
                            label
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ $t('upload.main_document') }}
                          </v-chip>
                        </template>

                        <span>{{ $t('main_document.info') }}</span>
                      </v-tooltip>
                    </div>

                    <div class="file-upload-box__size">
                      <span v-if="item.size"> - </span>
                      <template v-if="item.size"> {{ bytesToSize(item.size) }}</template>
                    </div>

                    <div class="file-upload-box__action">
                      <template v-if="item.error">
                        <span>{{ $t('upload.failed') }}</span>

                        <button
                          v-if="item.error && item.error !== 'compressing' && $refs.upload.features.html5"
                          type="secondary"
                          style="width: 160px"
                          @click="
                            $refs.upload.update(item, {
                              active: true,
                              error: '',
                              progress: '0.00',
                            })
                          "
                        >
                          {{ $t('upload.retry') }}
                        </button>

                        <button
                          class="file-upload-box__btn"
                          @click="deleteDocumentDialogMethod(true, item)"
                          type="button"
                          name="button"
                        >
                          <span class="icon icon-bin"></span>
                        </button>
                      </template>

                      <template v-else-if="item.success">
                        <span>{{ $t('general.done') }}</span>
                        <button
                          class="file-upload-box__btn"
                          @click="deleteDocumentDialogMethod(true, item)"
                          type="button"
                          name="button"
                        >
                          <span class="icon icon-bin"></span>
                        </button>
                      </template>

                      <template v-else-if="item.active">
                        <button
                          class="file-upload__btn"
                          type="secondary"
                          @click="$refs.upload.update(item, { active: false })"
                        >
                          {{ $t('general.uploading') }}
                          <v-progress-circular
                            :size="20"
                            color="primary"
                            indeterminate
                          ></v-progress-circular>
                        </button>
                      </template>

                      <template v-else>
                        <div class="file-upload-box__btn">
                          {{ $t('general.preparing_upload') }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>

                <v-expand-transition>
                  <v-row
                    v-if="checkFileExtension(item.name)"
                    align="start"
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="px-2 pb-2"
                    >
                      <span class="file-warning__text subtitle-2 font-weight-medium">
                        {{ $t('upload.format_alert') }}
                      </span>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </drag>
            </template>
          </drop-list>

          <label
            class="file-upload__more"
            v-if="files.length > 0 && !uploading && !imageUpload"
            :for="fileName"
          >
            {{ $t('upload.add_more') }}
          </label>
        </div>

        <!--        <div class="upload-box__item upload-box__item&#45;&#45;right upload-box__item&#45;&#45;right-layout">-->
        <!--          <div class="upload-box__item-wrap" @click="driveIconClicked()">-->
        <!--            <div class="upload-box__ico">-->
        <!--              <IcoGoogleDrive />-->
        <!--            </div>-->

        <!--            <div class="upload-box__name">-->
        <!--              Google drive-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="upload-box__item upload-box__item&#45;&#45;left upload-box__item&#45;&#45;left-layout">-->
        <!--          <div class="upload-box__item-wrap" @click="dropboxFileUpload()">-->
        <!--            <div class="upload-box__ico">-->
        <!--              <IcoDropbox />-->
        <!--            </div>-->

        <!--            <div class="upload-box__name">-->
        <!--              Dropbox-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>

    <div v-if="!files.length && !imageUpload">
      <v-checkbox
        v-model="convertToPdfA"
        class="ma-0 py-2"
        :label="$t('document.convert_to_pdf_a')"
        hide-details
      >
        <template v-slot:append>
          <s-help>
            <template v-slot:content>
              <p
                class="mb-0"
                v-html="
                  $t('document.convert_to_pdf_a.help', {
                    link: $t('links.confluence.convert_to_pdf_a'),
                  })
                "
              ></p>
            </template>
          </s-help>
        </template>
      </v-checkbox>
    </div>

    <div class="buttons buttons--center buttons-upload-box buttons--top">
      <Button
        :loading="loading"
        type="primary"
        v-if="this.files.length > 0 && canContinue"
        @click="updateContract()"
        >{{ $t('contract.create_draft') }}</Button
      >
    </div>

    <div
      v-if="canContinue && !isReplace"
      class="upload-box__tips"
    >
      <p>
        {{ $t('contract.buy_long.text') }}
        {{
          $tc('contract.buy_long.x_credits', this.files.length, {
            count: this.files.length,
          })
        }}
      </p>
    </div>

    <file-upload
      ref="upload"
      v-model="files"
      :name="fileName"
      :post-action="url"
      :drop="true"
      :headers="headers"
      :multiple="multiple"
      @input-filter="inputFilter"
      @input-file="inputFile"
    ></file-upload>

    <SConfirmDialog
      v-model="deleteDocumentDialogObject.show"
      :message="`${$t('docs.delete.confirm')}`"
      @confirmation-callback="removeUploadedFile(deleteDocumentDialogObject.file)"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { Drag, DropList } from 'vue-easy-dnd';
import { environment } from '@/config/environment';
import { AccountService } from '@/services/AccountService.js';
import { ContractService } from '@/services/ContractService.js';
import { getErrorResponseMessage } from '@/common/reusable/errorResponse';
import IcoUpload from '@/components/svg/IcoUpload.vue';
import FileUpload from 'vue-upload-component';
import GoogleDrive from '@/config/GoogleDrive';
import SConfirmDialog from '@/common/components/SConfirmDialog';
import { parseFileName } from '@/app/common/reusable/file';
import { supportedDocumentFileExtensions, supportedSignatureFileExtensions } from '@/app/config/variables';

export default {
  name: 'DocumentUploader',
  components: {
    SConfirmDialog,
    Drag,
    DropList,
    FileUpload,
    IcoUpload,
  },
  props: {
    contractId: {
      type: [Number, String],
      default: null,
    },
    workspaceId: {
      type: [Number, String],
      required: true,
    },
    createContract: {
      type: Boolean,
      default: false,
    },
    imageUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragging: false,
      clientId: null,
      developerKey: null,
      fileName: 'document',
      files: [],
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      loading: false,
      oauthToken: null,
      pickerApiLoaded: false,
      scope: 'https://www.googleapis.com/auth/drive.readonly',
      uploadAuto: true,
      showDisclaimer: false,
      convertToPdfA: false,
      deleteDocumentDialogObject: {
        show: false,
        file: {},
      },
      signatureImageData: {
        uploaded: false,
        data: {},
      },
    };
  },
  watch: {
    files: {
      deep: true,
      handler: function () {
        setTimeout(() => {
          this.dragging = false;
        }, 500);
      },
    },
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      workspaceById: 'workspaceById',
    }),
    uploading() {
      return this.files.some((file) => file.active);
    },
    isReplace() {
      return 'true' === `${this.$route.query?.replace}` || false;
    },
    multiple() {
      return !this.isReplace;
    },
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id);
    },
    url() {
      return `${environment.getApiUrl()}/api/v2/documents?convert=${this.convertToPdfA ? 1 : 0}`;
    },
    availableCredits() {
      return 'legal' === this.activeWorkspace.type ? this.activeWorkspace.owner_credits : this.profile.credits;
    },
    canContinue() {
      return this.files.length > 0 && this.files.every((file) => file.success && file.response.id);
    },
  },
  created() {
    const googleDrive = new GoogleDrive();

    this.clientId = googleDrive.getClientId();
    this.developerKey = googleDrive.getDeveloperKey();

    this.fetchData();

    this.convertToPdfA = this.activeWorkspace?.settings?.convertToPdfA || false;
  },
  mounted() {
    let dropBox = document.createElement('script');
    dropBox.setAttribute('src', 'https://www.dropbox.com/static/api/2/dropins.js');
    dropBox.setAttribute('id', 'dropboxjs');
    dropBox.setAttribute('data-app-key', '1nulvvuvi605jvr');
    document.head.appendChild(dropBox);

    let gDrive = document.createElement('script');
    gDrive.setAttribute('type', 'text/javascript');
    gDrive.setAttribute('src', 'https://apis.google.com/js/api.js');
    document.head.appendChild(gDrive);
  },
  methods: {
    bytesToSize(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (0 == bytes) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    async driveIconClicked() {
      // eslint-disable-next-line no-undef
      await gapi.load('auth2', () => {
        // eslint-disable-next-line no-undef
        gapi.auth2.authorize(
          {
            client_id: this.clientId,
            scope: this.scope,
            immediate: false,
          },
          this.handleAuthResult,
        );
      });

      // eslint-disable-next-line no-undef
      gapi.load('picker', () => {
        this.pickerApiLoaded = true;
        this.createPicker();
      });
    },
    handleAuthResult(authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      }
    },
    createPicker() {
      if (this.pickerApiLoaded && this.oauthToken) {
        // eslint-disable-next-line no-undef
        const picker = new google.picker.PickerBuilder()
          // eslint-disable-next-line no-undef
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          // eslint-disable-next-line no-undef
          .addView(google.picker.ViewId.DOCS)
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey(this.developerKey)
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);
      }
    },
    async pickerCallback(data) {
      // eslint-disable-next-line no-undef
      if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
        _.each(data.docs, (file) => {
          const fileinfoRequest = {
            document_id: file.id,
            document_name: file.name,
            document_size: file.sizeBytes,
            access_token: this.oauthToken,
          };
          this.processExternalUpload(fileinfoRequest.document_name, fileinfoRequest.document_size, () =>
            AccountService.googlediskFileinfo(fileinfoRequest),
          );
        });
      }
    },
    fetchData() {
      if (this.contractId) {
        ContractService.detail(this.contractId, this.workspaceId)
          .then((response) => {
            this.$store.commit('setContract', response);
          })
          .catch(() => {
            this.$notification.error(this.$t('contract.not_loaded'));
          });
      }
    },
    dropboxFileUpload() {
      const vm = this;
      // eslint-disable-next-line no-undef
      jQuery(document).ready(() => {
        vm.dropboxIconClicked();
      });
    },
    dropboxIconClicked() {
      let options = {
        success: async (files) => {
          _.each(files, (file) => {
            const fileInfoRequest = {
              id: file.id,
              title: file.name,
              size: file.bytes,
              iconURL: file.icon,
              link: file.link,
              extension: parseFileName(file.name)?.extension,
            };
            this.processExternalUpload(fileInfoRequest.title, fileInfoRequest.size, () =>
              AccountService.dropboxFileinfo(fileInfoRequest),
            );
          });
        },

        cancel: function () {},

        linkType: 'direct',
        multiselect: true,

        extensions: ['.pdf', '.doc', '.docx', '.odt', '.xls', '.xlsx', '.ods'],

        folderselect: false,
        sizeLimit: 10 * 1024 * 1024,
      };

      // eslint-disable-next-line no-undef
      Dropbox.choose(options);
    },
    processExternalUpload(title, size, uploadFile) {
      const file = {
        id: randomId(), // no file is visible without id
        name: title,
        size: size,
        active: true,
        success: false,
        error: null,
        response: null,
      };
      this.files.push(file);
      uploadFile().then(
        (document) => {
          file.response = document;
          file.success = true;
          file.active = false;
        },
        () => {
          file.error = 'upload error';
          file.active = false;
        },
      );

      function randomId() {
        return Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(2, 10);
      }
    },
    deleteDocumentDialogMethod(show, file) {
      this.deleteDocumentDialogObject = Object.assign({}, { show, file });
    },
    removeUploadedFile(selectedFile) {
      this.files = this.files.filter((f) => f !== selectedFile);
    },
    async updateContract() {
      if (this.availableCredits < this.files.length) {
        this.$notification.error(this.$t('upload.error_credits'));

        return this.$router.push({ name: 'subscription' });
      }

      this.loading = true;

      try {
        const requests = this.files.map((file) => ({
          title: parseFileName(file.name)?.name || file.name,
          type: 'doc',
          document: file.response.id,
          workspace_id: this.workspaceId,
        }));

        if (!this.contractId) {
          await ContractService.create(
            {
              ...requests[0],
              attachments: requests.slice(1),
            },
            this.activeWorkspace,
          ).then((resp) => {
            return this.$router.push({
              name: 'contractsCollectionParties',
              params: {
                contractId: resp?.id,
                workspaceId: this.workspaceId,
                contacts: this.$route.query?.contacts,
              },
            });
          });
        } else if ('true' !== this.$route?.query?.replace) {
          await ContractService.addAttachments(this.contractId, requests).then((resp) => {
            return this.$router.push({
              name: 'createContent',
              params: {
                contract_id: resp?.id || this.contractId,
                workspace_id: this.$route.params.workspace_id,
              },
            });
          });
        } else {
          await ContractService.update(this.contractId, {
            document_id: requests[0].document,
          }).then(() => {
            return this.$router.push({
              name: 'createContent',
              params: {
                contract_id: this.contractId,
                workspace_id: this.$route.params.workspace_id,
              },
            });
          });
        }
      } catch (err) {
        if (err?.translationKey) {
          return this.$notification.error(getErrorResponseMessage(err));
        }

        if ('Uživatel nemá dostatek kreditů' === err?.message || 'Uživatel nemá dostatek kreditů' === err?.statusText) {
          this.$notification.error(this.$t('upload.error_credits'));

          return this.$router.push({ name: 'subscription' });
        }

        this.$notification.error(this.$t('upload.error_general'));
      }

      this.loading = false;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        if (
          newFile.file &&
          'image/' === newFile.type.substr(0, 6) &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = 'compressing';
          // eslint-disable-next-line no-undef
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512,
          });
          imageCompressor
            .compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, {
                error: '',
                file,
                size: file.size,
                type: file.type,
              });
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, {
                error: err.message || 'compress',
              });
            });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        newFile.thumb = '';
        if (newFile.blob && 'image/' === newFile.type.substr(0, 6)) {
          newFile.thumb = newFile.blob;
        }
      }

      if (
        newFile &&
        'denied' === newFile.error &&
        newFile.name.indexOf('docx') !== -1 &&
        newFile.response &&
        newFile.response.title &&
        newFile.response.title.indexOf('Nepodporovaný formát') !== -1 &&
        !newFile.active
      ) {
        this.$notification.error(this.$t('upload.error_docs'));
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
            this.$refs.upload.update(newFile, { error: 'size' });
          }
        }
      }

      if (this.imageUpload) {
        if (newFile.size > 614400) {
          return this.$emit('upload-image-errors', { fileSizeExceeded: true, size: newFile.size });
        }
      }

      // signature image upload
      if (this.imageUpload && newFile.success) {
        this.uploadImage(newFile);
      }

      // Automatically activate upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    async uploadImage(newFile) {
      await this.signatureImageUpload(newFile)
        .then((response) => {
          this.$emit('save-image', response);
        })
        .catch(() => {
          this.$emit('upload-image-errors', { fileExtensionSupported: false });
        });
    },
    signatureImageUpload(imageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile.file);

      return new Promise(function (resolve, reject) {
        reader.addEventListener('loadend', function () {
          resolve(
            (this.signatureImageData = {
              uploaded: true,
              data: reader.result,
            }),
          );
        });

        const fileExtension = parseFileName(imageFile.name)?.extension || '';
        const isFileExtensionSupported = supportedSignatureFileExtensions?.includes(fileExtension.toLowerCase());

        if (!isFileExtensionSupported) {
          reject();
        }
      });
    },
    checkFileExtension(fileName) {
      const fileExtension = parseFileName(fileName)?.extension || '';

      return !supportedDocumentFileExtensions?.includes(fileExtension.toLowerCase());
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/mixins';

.upload__box-wrapper {
  background: #ffffff;
  min-height: 480px;
  max-width: 845px;
  width: 100%;
  border: 2px dashed #b4c1d6;
  border-radius: 6px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px 120px;

  &--dragging {
    border: 2px solid var(--v-primary-base);
  }

  @include md {
    min-height: unset;
    height: unset;
    padding: 1rem;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
.upload-box__item > button {
  border: none;
  background: transparent;
  width: 100%;
  display: block;
  cursor: pointer;
  height: 90px;
  line-height: 90px;
}

.upload-box__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload-box {
  padding: 0 2rem 0.5rem 2rem;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include clearfix;
  &:nth-child(1) {
    padding-top: 20px;
  }
  @include md {
    padding: 0 10px 10px 10px;
    text-align: left;
  }
}

.file-upload-box__btn {
  border: none;
  background: transparent;
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  padding: 0 8px;
  cursor: pointer;
}

.file-upload-box__name {
  float: left;
  vertical-align: middle;
  font-size: 16px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;

  @include md {
    float: none;
    width: 100%;
    text-align: left;
    padding-right: 10px;
  }
}

.file-upload-box__size {
  float: left;
  padding-left: 10px;
  vertical-align: middle;
  font-size: 16px;

  @include md {
    display: block;
    padding: 10px 0;
    float: none;
    width: 100%;
    text-align: center;

    span {
      display: none !important;
    }
  }
}

.file-upload-box__action {
  font-size: 16px;
  float: right;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;

  @include md {
    float: none;
    width: 100%;
    text-align: center;
  }
}

.template-wrap .file-uploads {
  display: block;
  text-align: center;
}

.upload-table {
  width: 100%;
  margin: 45px 0;
  border-collapse: collapse;
  font-size: 16px;
  text-align: left;

  th,
  td {
    text-align: left;
    padding: 10px;
  }
}

.upload-table__drop {
  border: 2px dashed #cdcdcd;
  padding: 20px;
  margin: 20px 0;
}

.upload-box {
  text-align: center;
  margin-bottom: 20px;
  @include md {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    p {
      display: none;
    }
  }
  h2 {
    text-align: center;
    @include md {
      text-align: left;
    }
  }
}

.upload-box__items {
  @include md {
    width: 100%;
  }
}

.upload-box__item {
  position: relative;
  box-sizing: border-box;
  width: 50%;
  float: left;
  line-height: 60px;
  height: 60px;
  margin-bottom: 10px;
  border: 2px solid #9ea3ae;
  border-radius: 6px;
  @include clearfix;

  @include md {
    height: 60px;
    line-height: 60px;
  }

  .upload-box__ico {
    line-height: 60px;
  }
}

.upload-box__item--auto {
  height: auto;
  line-height: 1;
}

.upload-box__item--full {
  flex-direction: column;
  width: 100%;
  min-height: 60px;
  .upload-box__ico {
    line-height: 60px;
  }
}

.upload-box__item.disabled:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
}

.upload-box__item--left {
  width: calc(50% - 5px);
  margin-left: 5px;
  @include md {
    margin: 0;
  }
}

.upload-box__item--right {
  width: calc(50% - 5px);
  margin-right: 5px;
}

.upload-box__item__container {
  height: 60px;
  line-height: 60px;
  @include clearfix;
  @include md {
    width: 100%;
  }
}

.upload-box__item-wrap {
  cursor: pointer;

  @include md {
    width: 100%;
  }
}

.upload-box__ico {
  float: left;
  display: inline-block;
  line-height: 90px;

  svg {
    vertical-align: middle;
  }
  @include md {
    line-height: 80px;
    padding-left: 20px;
  }
}

.upload-box__name {
  float: left;
  display: inline-block;
  font-size: 19px;
  padding-left: 15px;
  @include md {
    font-weight: 300;
    font-size: 15px;
  }
}

.upload-box__desc {
  position: absolute;
  top: 20px;
  font-size: 12px;
}

.upload-box__item--bottom-layout {
  @include md {
    width: 100%;
    border: 1px solid #edf4fe;
    margin-bottom: 10px;
    background: white;
    border-radius: 6px;
    margin-top: 70px;
  }
}

.upload-box__item--right-layout {
  @include md {
    width: 100%;
  }
}

.upload-box__item--left-layout {
  @include md {
    width: 100%;
  }
}

.file-upload-box__size {
  display: none;
}

.buttons-upload-box {
  margin-top: 0;
}

.file-upload-box__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drop-list,
.upload-box {
  width: 100%;
}
.file-upload__more {
  font-size: 14px;
  color: black;
  padding: 10px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
.file-upload__btn {
  @include md {
    display: none;
  }
}

.file-warning__text {
  color: var(--v-warning-base);
}
</style>
